import { Component } from '@angular/core';
import { LoaderService } from './core/services/loader.service';
declare global {
  interface Window {
    Intercom(
      arg0: string,
      arg1?: {
        app_id?: string;
        user_id?: string;
        name?: string;
        email?: string;
        created_at?: number;
        user_type?: string;
        last_seen?: number;
      }
    ): any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showLoading!: boolean;
  loadingText!: string;

  constructor(private loaderService: LoaderService) {
    window.Intercom('boot', {
      app_id: 'kvqogpa7',
    });
    this.loaderService.getShowLoading$.subscribe(showLoading => {
      this.showLoading = showLoading;
    });
    this.loaderService.getLoadingText$.subscribe(loadingText => {
      this.loadingText = loadingText;
    });
  }

}
