import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';

const routes: Routes =  [
  {
      path: '',
      loadChildren: () =>
      import('./components/pages/entry/entry.module').then(m => m.EntryModule)
  },
  {
      path: 'dashboard',
      loadChildren: () =>
          import('./components/pages/main/main.module').then(m => m.MainModule)
  },
  { path: '**', component: NotFoundComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
