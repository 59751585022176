// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const appVersion = require('./../../package.json').version;
export const environment = {
  production: false,
  neat_be: 'https://neatwebplatform-beta.appspot.com',
  neat_be_local: 'http://localhost:8080/api',
  firebase: {
    apiKey: 'AIzaSyASYMQur8Cd2oleCg8yOluYDb_NKXYQ3Yc',
    authDomain: 'neatwebplatform-beta.firebaseapp.com',
    databaseURL: 'https://neatwebplatform-beta.firebaseio.com',
    projectId: 'neatwebplatform-beta',
    storageBucket: 'neatwebplatform-beta.appspot.com',
    messagingSenderId: '83408358997',
    appId: '1:83408358997:web:099418f74040137a',
    measurementId: 'G-1QM0D4K1R8',
    fcmPublicKey: 'BO6E6IjSsUfQ2OY5fnJUF7gUk9bPQkNxSn634gBC2kzrYEw6LOFJCxsSUMLIv1ltwK-LBw7QAT-FaWYy0kUsycI'
  },
  firebaseMX: {
    apiKey: "AIzaSyAS45S0yWoHLml_SGQuFgRLbfuExk6kyZs",
    authDomain: "neat-mx-beta.firebaseapp.com",
    projectId: "neat-mx-beta",
    storageBucket: "neat-mx-beta.appspot.com",
    messagingSenderId: "705544272547",
    appId: "1:705544272547:web:beeffbb4981221e14add16",
    measurementId: "G-SELP3BTTQZ"
  },
  lastModified: '#LAST_MODIFIED#',
  versionApiKey: 'A16765C314AC483',
  //kushkiMerchantId: '80e68eeec59243cda277e80c783f5e1e' CL
  kushkiMerchantId: '2995e4ce95bd4eac844a4e3185558d99'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.