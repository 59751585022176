import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loadingText: Subject<string> = new BehaviorSubject<string>('Cargando ...');
  private showLoading: Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  get getLoadingText$(): Observable<string> {
    return this.loadingText.asObservable();
  }

  get getShowLoading$(): Observable<boolean> {
    return this.showLoading.asObservable();
  }

  showLoader(showLoaderText: string): void {
    this.showLoading.next(true);
    this.loadingText.next(showLoaderText);
  }

  hideLoader(): void {
    this.showLoading.next(false);
    this.loadingText.next('');
  }

}
